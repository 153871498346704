import React, { useState } from 'react'
import cn from 'classnames'

import ArrowDown from './Icons/ArrowDown'
import ArrowUp from './Icons/ArrowUp'
import DivvyDOSEIcon from './Icons/DivvyDOSEIcon'
import Phone from './Icons/Phone'

import Gutter from '../Gutter'
import A, { AVariants } from '../Type/A'

import { data, phoneData } from './data'

import './styles/mobileNavControls.css'
import './styles/nav.css'

export interface NavLink {
  text: string
  url: string
}
export interface NavProps {
  links?: NavLink[]
  subListLinks?: NavLink[]
  phoneLink?: NavLink
}

const NavWrapperStyles = `
  fixed
  w-full
  bg-mono-white
  shadow
  py-4
`

const NavGridWrapper = `
  grid
  items-center
`

const LogoWrapperStyles = `
  inline-block
  justify-self-start
  w-36
  lg:w-40
`

const RightNavWrapper = `
  justify-self-end
`

const MoreButtonStyles = `
  text-mono-600
  hover:text-primary
  pr-3
  xl:pr-5
`

const DesktopLinkStyles = `
  px-3
  xl:px-5
  text-mono-600
  hover:text-primary
`
const MobileLinkStyles = `
  text-mono-600
  hover:text-primary
  block
  w-screen
  bg-mono-white
  py-3
  px-5
`

const MainLinksWrapperStyles = `
  hidden
  lg:inline-block
`

const SecondaryLinkGroupWrapperStyles = `
  hidden
  lg:inline-block
  relative
  px-3
  xl:px-5
`

const DropDownWrapperStyles = `
  hidden
  lg:inline-block
`
const ArrowWrapperStyles = `
  inline-block
  w-4
`
const SecondaryLinksWrapperStyles = `
  inline-block
  absolute
  top-11
  left-0
  bg-mono-white
  divide-y
  divide-mono-400
  w-56
  shadow-lg
`

const SecondaryLinkStyles = `
  block
  py-5
`

const PhoneLinkWrapperStyles = `
  inline-block
`
const DesktopPhoneStyles = `
  hidden
  lg:inline
  pr-0
`
const MobilePhoneStyles = `
  lg:hidden
  inline-block
  w-5
  mx-8
  lg:ml-3
  align-middle
`
const MobileNavControlsGroupWrapperStyles = `
  lg:hidden
  inline-block
  align-middle
`
const MobileNavItemsGroupStyle = `
  absolute
  left-0
  top-16
  block
  lg:hidden
  divide-y
  divide-mono-400
  bg-mono-white
  shadow-lg
`

export default function Nav({ links = data, phoneLink = phoneData }: NavProps) {
  const [desktopNavTab, setDesktopNavTab] = useState('closed')
  const [mobileNavTab, setMobileNavTab] = useState('closed')

  function changeNavTab(status: string, setTab: Function) {
    if (status === 'closed') {
      setTab('open')
    } else {
      setTab('closed')
    }
  }

  return (
    <>
      <nav className={cn(NavWrapperStyles, 'navSection')}>
        <Gutter>
          <div className={cn(NavGridWrapper, 'navSection-gridWrapper')}>
            <div className={LogoWrapperStyles}>
              <a aria-label="homepage" href="https://divvydose.com/">
                <DivvyDOSEIcon />
              </a>
            </div>

            <div className={RightNavWrapper}>
              <div className={MainLinksWrapperStyles}>
                {links.map((item, key) => (
                  <A
                    key={`navItem-${key}`}
                    url={item.url}
                    className={DesktopLinkStyles}
                  >
                    {item.text}
                  </A>
                ))}
              </div>

              <div className={PhoneLinkWrapperStyles}>
                <A url={phoneLink.url} className="align-middle">
                  <span className={cn(DesktopLinkStyles, DesktopPhoneStyles)}>
                    {phoneLink.text}
                  </span>
                  <span className={MobilePhoneStyles}>
                    <Phone />
                  </span>
                </A>
              </div>

              <button
                onClick={() => changeNavTab(mobileNavTab, setMobileNavTab)}
                className={cn(MobileNavControlsGroupWrapperStyles)}
              >
                <div className="hamburger">
                  <span
                    className={cn(
                      'main-nav-toggle',
                      mobileNavTab === 'closed' ? '' : 'active-menu'
                    )}
                  >
                    <i>Menu</i>
                  </span>
                </div>
              </button>
              <div
                className={cn(
                  MobileNavItemsGroupStyle,
                  mobileNavTab === 'closed' ? 'hidden' : '',
                  'navDrawer'
                )}
              >
                {links.map((item, key) => (
                  <A
                    key={`mobile-navItem-${key}`}
                    url={item.url}
                    className={MobileLinkStyles}
                  >
                    {item.text}
                  </A>
                ))}
              </div>
            </div>
          </div>
        </Gutter>
      </nav>
      <div className="navSection-spacer" />
    </>
  )
}
