export const data = [
  {
    text: 'Home',
    url: 'https://divvydose.com/'
  },
  {
    text: 'About us',
    url: 'https://divvydose.com/about/'
  },
  {
    text: 'Contact us',
    url: 'https://divvydose.com/contact-us/'
  }
]

export const phoneData = {
  text: '844-693-4889',
  url: 'tel:8446934889'
}
