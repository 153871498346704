export const data = [
  {
    text: 'Home',
    url: 'https://divvydose.com/'
  },
  {
    text: 'About us',
    url: 'https://divvydose.com/about/'
  },
  {
    text: 'Contact us',
    url: 'https://divvydose.com/contact-us/'
  }
]

export const logoData = {
  text: 'NABP Badge',
  url: 'https://app.us.openbadges.me/view/#!/badge?badgeid=497b960f-c9fd-41f5-b4c5-679650a64962'
}
